import React from 'react';
import { BiStar, BiSolidStar, BiSolidStarHalf } from "react-icons/bi";

export const StarRatingDisplay = ({ 
    title, 
    rating = 0, 
    hoveredRating = {}, 
    selectedRating = {}, 
    onRatingClick, 
    setHoveredRating,
    readOnly = false,
    existingRating = null
}) => {
    const isDisabled = existingRating !== null && existingRating > 0;
    const effectiveReadOnly = readOnly || isDisabled;

    return (
        <div className="flex items-center mt-2">
            {[1, 2, 3, 4, 5].map((star) => (
                <span 
                    key={star} 
                    className={`text-xl ${effectiveReadOnly ? 'cursor-default' : 'cursor-pointer'} relative`}
                    {...(!effectiveReadOnly && {
                        onMouseMove: (e) => {
                            const rect = e.currentTarget.getBoundingClientRect();
                            const halfPoint = rect.left + rect.width / 2;
                            const isHalfStar = e.clientX < halfPoint;
                            setHoveredRating(prev => ({
                                ...prev,
                                [title]: isHalfStar ? star - 0.5 : star
                            }));
                        },
                        onMouseLeave: () => setHoveredRating(prev => ({ ...prev, [title]: 0 })),
                        onClick: (e) => {
                            const rect = e.currentTarget.getBoundingClientRect();
                            const halfPoint = rect.left + rect.width / 2;
                            const isHalfStar = e.clientX < halfPoint;
                            onRatingClick(title, isHalfStar ? star - 0.5 : star);
                        }
                    })}
                >
                    {(() => {
                        const currentRating = effectiveReadOnly 
                            ? (existingRating || rating)
                            : (hoveredRating[title] || selectedRating[title] || rating);

                        if (star <= currentRating) {
                            return <BiSolidStar className="text-yellow-400" />;
                        } else if (star - 0.5 <= currentRating) {
                            return <BiSolidStarHalf className="text-yellow-400" />;
                        } else {
                            return <BiStar className="text-gray-300" />;
                        }
                    })()}
                </span>
            ))}
            {isDisabled && (
                <span className="ml-2 text-sm text-gray-500">
                    Your rating: {existingRating}
                </span>
            )}
        </div>
    );
};

import React from 'react';

const VocabularyHeatmap = ({ vocabularyData }) => {
  // Add navigation handler
  const handleVocabClick = (kanji) => {
    window.location.href = `/kanji/${kanji}`;
  };

  // ----- 1. Helper to pick a background color from an accuracy scale -----
  const getBackgroundColor = (accuracy) => {
    // Convert accuracy (0..1) to a scale 0..10
    const rawScale = Math.ceil(accuracy * 10); // e.g. 0.33 => 4 on the 10 scale
    const clampedScale = Math.max(Math.min(rawScale, 10), 0);

      const colors = {
        0: "#ebedf0",
        1: "#85CDE5",
        2: "#64C0DF",
        3: "#43B4D9",
        4: "#22A7D3",
        5: "#009bce",
        6: "#008AB8",
        7: "#00779E",
        8: "#006282",
        9: "#004B63",
        10: "#003140",
        };
    
    return colors[clampedScale] || '#ebedf0';
  };

  const getTextColor = (accuracy) => {
    // Switch to white text when accuracy is above 60%
    return accuracy > 0.6 ? 'text-white' : 'text-black';
  };

  return (
    <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10 mb-16">
      <h2 className="text-2xl sm:text-3xl font-bold text-black mb-4 sm:mb-6 sm:text-left">
        Your Vocabulary Progress
      </h2>
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-2 justify-items-stretch">
        {vocabularyData.map((vocab, index) => {
          const accuracyPercent = (vocab.accuracy * 100).toFixed(0);
          const bgColor = getBackgroundColor(vocab.accuracy);
          const textColor = getTextColor(vocab.accuracy);

          return (
            <div
              key={index}
              onClick={() => handleVocabClick(vocab.kanji)}
              className="flex flex-col items-center justify-center p-2 rounded-md border border-gray-200 shadow-sm hover:shadow-md transition-shadow cursor-pointer hover:scale-105"
              style={{ backgroundColor: bgColor }}
              title={`${accuracyPercent}% Accuracy`}
            >
              <div className={`text-base font-semibold text-center ${textColor}`}>
                {vocab.word}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VocabularyHeatmap;

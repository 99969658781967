export const getLevelKanjiPercentage = (mappingString, userLevel) => {
    if (!mappingString || !userLevel) return 0;
    
    try {
        const level = parseInt(userLevel, 10);
        const pairs = mappingString.split(',');
        const levelMap = new Map(
            pairs.map(pair => {
                const [level, percentage] = pair.split(':');
                return [parseInt(level), parseFloat(percentage)];
            })
        );
        
        return levelMap.get(level) || 0;
    } catch (error) {
        console.error('Error parsing level mapping:', error);
        return 0;
    }
};

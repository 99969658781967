import React, { useEffect, useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import { MoonLoader } from 'react-spinners';

/**
 * Props:
 *   isOpen (bool): whether to show the popover
 *   boundingRect (object): the position/size info of the clicked element
 *   onClose (func): callback to close the popover
 *   content (array): the kanji data to display
 */
function KanjiPopup({ isOpen, boundingRect, onClose, content }) {
  // Popper references
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  // For loading spinner
  const [isLoading, setIsLoading] = useState(true);

  // React Popper hook: returns styles, attributes, etc.
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom', // default to bottom
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
      { 
        name: 'preventOverflow',
        options: {
          boundary: 'viewport',
          padding: 8,
          altAxis: true, // Prevents overflow on both axes
          tether: false // Allows the popup to detach from the reference if needed
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'left'],
          padding: 8
        }
      },
      {
        name: 'maxSize',
        enabled: true,
        options: {
          max: 'viewport',
          padding: 8
        },
      }
    ],
  });

  // When isOpen changes, reset loading state
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const timer = setTimeout(() => setIsLoading(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen, content]);

  // Add new useEffect for Popper position update
  useEffect(() => {
    if (isOpen && update) {
      requestAnimationFrame(() => {
        update();
      });
    }
  }, [isOpen, update, content]);

  // If not open or no boundingRect, no popover at all
  if (!isOpen || !boundingRect) return null;

  // Helper function: format content
  const formatKanjiData = (data) => {
    if (!data) {
      return (
        <div className="flex items-center justify-center min-h-[160px] min-w-[400px]">
          <MoonLoader size={24} color="#009BCE" />
        </div>
      );
    }
    const dataArray = Array.isArray(data) ? data : [data];

    return dataArray.map((item, index) => {
      if (item.isVocabulary) {
        // Helper for displaying commonality
        const getCommonalityDisplay = (commonality) => {
          const styles = {
            'very common': { color: 'text-green-600', dots: '●●●●●' },
            'common': { color: 'text-green-500', dots: '●●●●○' },
            'uncommon': { color: 'text-yellow-500', dots: '●●●○○' },
            'rare': { color: 'text-orange-500', dots: '●●○○○' },
            'very rare': { color: 'text-red-500', dots: '●○○○○' },
          };
          return styles[commonality?.toLowerCase()] || {
            color: 'text-gray-400',
            dots: '○○○○○',
          };
        };
        const commonalityDisplay = getCommonalityDisplay(item.commonality);

        return (
          <div key={index} className="mb-6">
            <div className="flex items-center gap-6">
              {/* Vocabulary word */}
              <div className="text-2xl lg:text-6xl font-bold min-w-[60px] lg:min-w-[80px] text-center">
                {item.word}
              </div>

              {/* Vocabulary info */}
              <div className="flex-1">
                <div className="flex flex-col gap-1 lg:gap-2">
                  <div>
                    <span className="text-gray-500 text-sm lg:text-base">Definition:</span>{' '}
                    <span className="text-sm lg:text-lg">{item.definition}</span>
                  </div>
                  <div>
                    <span className="text-gray-500 text-sm lg:text-base">Part of Speech:</span>{' '}
                    <span className="text-sm lg:text-base italic">{item.partOfSpeech}</span>
                  </div>
                  <div>
                    <span className="text-gray-500 text-sm lg:text-base">Nuance:</span>{' '}
                    <span className="text-xs lg:text-sm text-gray-700">{item.nuance}</span>
                  </div>
                  <div className={`${commonalityDisplay.color} text-sm lg:text-base`}>
                    <span className="text-gray-500">Commonality:</span>{' '}
                    <span>{item.commonality}</span>{' '}
                    <span className="tracking-tight">{commonalityDisplay.dots}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 border-t border-gray-200 mx-auto opacity-60" />
          </div>
        );
      }

      // Check if kanjiItem is valid
      if (!item || (!item.kanjiInfo && !item.message)) {
        return (
          <div
            key={index}
            className="mb-6 last:mb-0 min-h-[160px] min-w-[400px] flex items-center justify-center"
          >
            <MoonLoader size={24} color="#009BCE" />
          </div>
        );
      }

      return (
        <div key={index}>
          <div className="mb-4">
            {item.message ? (
              <p className="text-gray-600">{item.message}</p>
            ) : (
              <div className="flex items-center gap-6">
                {/* Kanji character */}
                <div className="text-2xl lg:text-6xl font-bold min-w-[60px] lg:min-w-[80px] text-center">
                  {item.kanji || '?'}
                </div>

                {/* Info table */}
                <div className="flex-1">
                  {/* Meanings row */}
                  <div className="flex gap-2 lg:gap-4 text-xs lg:text-sm mb-2 lg:mb-3">
                    <div>
                      <span className="text-gray-500">Primary:</span>{' '}
                      {item.kanjiInfo?.primaryMeaning || 'N/A'}
                    </div>
                    <div>
                      <span className="text-gray-500">Secondary:</span>{' '}
                      {item.kanjiInfo?.secondaryMeaning
                        ? item.kanjiInfo.secondaryMeaning.split(',').slice(0, 2).join(', ')
                        : 'N/A'}
                    </div>
                  </div>

                  {/* Readings row */}
                  {(item.onyomi?.readings?.filter((r) => r !== 'None').length > 0 ||
                    item.kunyomi?.readings?.filter((r) => r !== 'None').length > 0) && (
                    <div className="mb-2 lg:mb-3">
                      <div className="text-xs lg:text-sm text-gray-500 mb-1">Readings</div>
                      <div className="flex gap-2 lg:gap-4 text-xs lg:text-sm">
                        {item.onyomi?.readings?.filter((r) => r !== 'None').length > 0 && (
                          <div>
                            <span className="text-xs font-semibold text-darkBlueColor">ON:</span>{' '}
                            <span className="text-darkBlueColor">
                              {item.onyomi.readings.filter((r) => r !== 'None').join(', ')}
                            </span>
                          </div>
                        )}
                        {item.kunyomi?.readings?.filter((r) => r !== 'None').length > 0 && (
                          <div>
                            <span className="text-xs font-semibold text-orange-700">KUN:</span>{' '}
                            <span className="text-orange-700">
                              {item.kunyomi.readings.filter((r) => r !== 'None').join(', ')}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Level info row */}
                  <div className="flex gap-2 lg:gap-4 text-xs lg:text-sm">
                    <div>
                      <span className="text-gray-500">JLPT:</span>{' '}
                      {item.kanjiInfo?.JLPT || 'N/A'}
                    </div>
                    <div>
                      <span className="text-gray-500">OniKanji Level:</span>{' '}
                      {item.kanjiInfo?.level || 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {index < dataArray.length - 1 && (
            <div className="mb-4">
              <div className="border-t border-gray-200 mx-auto opacity-60" />
            </div>
          )}
        </div>
      );
    });
  };

  return (
    // Full-screen overlay for dimming background & capturing clicks
    <div
      className="fixed inset-0 z-40 bg-black bg-opacity-10 transition-opacity duration-200"
      style={{ pointerEvents: 'auto' }}
      onClick={onClose}
    >
      {/* 
        1) Dummy reference element (hidden) 
           Positioned exactly where boundingRect is, so Popper can anchor to it.
      */}
      <div
        ref={setReferenceElement}
        style={{
          position: 'absolute',
          top: boundingRect.top,
          left: boundingRect.left,
          width: boundingRect.width,
          height: boundingRect.height,
        }}
      />

      {/* 
        2) The actual popper container 
           Use the inline styles from Popper, plus the given attributes 
      */}
      <div
        ref={setPopperElement}
        style={{ ...styles.popper, pointerEvents: 'auto' }}
        {...attributes.popper}
        className={`
          z-50 
          bg-white border border-gray-200 shadow-xl rounded-lg 
          p-6
          transform transition-transform duration-200
          origin-top
          max-h-[80vh] overflow-y-auto
          ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}
        `}
        // Prevent the overlay from closing if user clicks inside the popper
        onClick={(e) => e.stopPropagation()}
      >
        {/* 3) Arrow element, also styled by Popper */}
        <div
          ref={setArrowElement}
          style={styles.arrow}
          className="
            w-0 h-0
            border-l-8 border-r-8 border-b-8
            border-l-transparent border-r-transparent border-b-white
            filter drop-shadow-sm
          "
          // If you want Popper to flip the arrow position, you can conditionally style
          // top, bottom, left, right. For a basic approach, let Popper handle it.
          {...attributes.arrow}
        />

        {/* 4) Close button (X icon) */}
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 
                     transition-colors duration-200 p-1 rounded-full 
                     hover:bg-gray-100"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* 5) Actual content area */}
        <div
          className="
            text-lg leading-relaxed text-gray-800 mt-1 
            font-noto-sans-jp tracking-wide
            prose prose-slate max-w-none
            [&>p]:mb-3 [&>p:last-child]:mb-0
          "
        >
          {isLoading ? (
            <div className="flex items-center justify-center min-h-[160px] min-w-[400px]">
              <MoonLoader size={24} color="#009BCE" />
            </div>
          ) : (
            formatKanjiData(content)
          )}
        </div>
      </div>
    </div>
  );
}

export default KanjiPopup;

import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { BiHelpCircle, BiXCircle } from "react-icons/bi";

import { createCallableFunction } from '../config/firebase';
import { logEvent } from "firebase/analytics";
import { analytics } from '../config/firebase';

//import modules
import { convertRomajiToHiragana } from '../components/utils/languageUtils';
import hiraganaKeyboardImage from '../images/hiragana-keyboard-mapping.png';
import { fetchAllKanjiData } from '../components/utils/fetchKanjiData';
import { checkAnswer } from '../components/utils/answerChecker';
import { renderSentenceWithFurigana } from '../components/utils/furiganaSentenceBuilder';

//Lesson Completed
import oniKanjiLessonCompletedImage from '../images/appHomeAssets/onikanji-learn-button-graphic.webp';
import { ClipLoader } from 'react-spinners'; 

import correctSound from '../images/sounds/right_answer_onikanji.mp3';
import incorrectSound from '../images/sounds/wrong_answer_onikanji.wav';
import correct from '../images/correct.png';
import wrong from '../images/wrong.png';
import accuracy from '../images/accuracy.png';
import skip from '../images/skip.png';
import sound_on from '../images/sound_on.png';
import sound_off from '../images/sound_off.png';

import { MdKeyboardTab } from "react-icons/md";

function KanjiLessonQuiz() {
    const location = useLocation();
    const kanjiData = location.state?.kanjiData;
    const [allKanjiData, setAllKanjiData] = useState({});
    const [quizList, setQuizList] = useState([]);
    const [onScreenQuestion, setOnScreenQuestion] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState([]);
    const [wrongAnswers, setWrongAnswers] = useState({});
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState("");
    const [isCorrect, setIsCorrect] = useState(null);
    const [quizFinished, setQuizFinished] = useState(false);
    const progressUpdatePromise = useRef(null);
    const [quizMode, setQuizMode] = useState('english');
    const progressUpdated = useRef(false);
    const { pathname } = useLocation();
    const [showContext, setShowContext] = useState(false);
    const inputRef = useRef(null);
    const [isSkipping, setIsSkipping] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [showContextHint, setShowContextHint] = useState(false);
    const [showingAnswer, setShowingAnswer] = useState(false);
    const [useKatakana, setUseKatakana] = useState(false);
    const [stats, setStats] = useState({
        correct: 0,
        wrong: 0,
        skip: 0,
        accuracy: 0
    });
    const [highlightedStat, setHighlightedStat] = useState(null);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const correctAudio = useRef(new Audio(correctSound));
    const incorrectAudio = useRef(new Audio(incorrectSound));

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        if (showTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    useEffect(() => {
        document.title = "OniKanji - Quiz";
        const initializeQuiz = () => {
            if (kanjiData) {
                setAllKanjiData(kanjiData);
                setWrongAnswers({});

                const quizList = Object.entries(kanjiData).flatMap(([kanjiKey, kanjiInfo]) => {
                    const primaryMeanings = [kanjiInfo.primaryMeaning];
                    const secondaryMeanings = kanjiInfo.secondaryMeaning ? [kanjiInfo.secondaryMeaning] : [];
                    const combinedMeanings = [...primaryMeanings, ...secondaryMeanings];

                    const createReadingQuestions = (readingType) => {
                        if (kanjiInfo[readingType] && kanjiInfo[readingType].readings) {
                            return [{
                                kanji: kanjiKey,
                                type: readingType,
                                value: kanjiInfo[readingType].readings,
                                inputType: 'hiragana'
                            }];
                        }
                        return [];
                    };

                    return [
                        { kanji: kanjiKey, type: 'primaryMeaning', value: combinedMeanings, inputType: 'english' },
                        ...createReadingQuestions('kunyomi'),
                        ...createReadingQuestions('onyomi')
                    ].filter(item => item.value.length > 0);
                });

                const shuffleArray = (array) => {
                    for (let i = array.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [array[i], array[j]] = [array[j], array[i]];
                    }
                    return array;
                };
                

                const shuffledQuizList = shuffleArray(quizList);
                setQuizList(shuffledQuizList);

                if (shuffledQuizList.length > 0) {
                    nextQuestionHandler(shuffledQuizList);
                }

                setLoading(false);
            }
        };

        if (!userLoading) {
            if (!user) {
                navigate('/login');
            } else {
                initializeQuiz();
            }
        }
    }, [user, userLoading, kanjiData, navigate]);

    const nextQuestionHandler = (updatedQuizList) => {
        // Prevent function from running if the quiz is already finished
        if (quizFinished) {
            return;
        }
    
        if (updatedQuizList.length === 0) {
            setOnScreenQuestion(null);
            setCorrectAnswer([]);
            setQuizFinished(true);
    
            const allKanji = Object.keys(kanjiData);
            const uniqueKanji = [...new Set(allKanji)];
    
            // Ensure updateUserProgressAfterLesson is only called once
            if (!progressUpdated.current) {
                progressUpdated.current = true; // Set this flag first to prevent duplicate calls
                progressUpdatePromise.current = updateUserProgressAfterLesson(uniqueKanji);
            }
            return;
        }
    
        const nextQuestion = updatedQuizList[0];
        setOnScreenQuestion(nextQuestion);
        setQuizList(updatedQuizList);
    
        const valueArray = Array.isArray(nextQuestion.value) ? nextQuestion.value : [nextQuestion.value];
        const processedAnswer = valueArray.map(ans => ans.toLowerCase().trim());
        setCorrectAnswer(processedAnswer);
    
        const mode = nextQuestion.type === 'primaryMeaning' ? 'english' : 'hiragana';
        setQuizMode(mode);
    
        setUserInput('');
        setIsCorrect(null);
        setShowContext(false);
    
        if (updatedQuizList.length > 0) {
            const nextQuestion = updatedQuizList[0];
            if (wrongAnswers[nextQuestion.kanji] === 1) {
                setShowContext(true);
            }
        }
    };
    
    const handleQuestionSubmission = (event) => {
        if (event.key === 'Enter' || event.type === 'click') {
            if (isCorrect !== null) {
                
                // Reset the context hint and answer display
                setShowContextHint(false);
                setShowingAnswer(false);

                // Move to the next question
                setQuizList((prevQuizList) => {
                    const updatedQuizList = prevQuizList.slice(1);

                    nextQuestionHandler(updatedQuizList);
                    return updatedQuizList;
                });
            } else {

                // Check the answer
                const isCorrectAnswer = checkAnswer(onScreenQuestion.inputType, correctAnswer, userInput);
                

                if (isCorrectAnswer) {
                    setIsCorrect(true);
                    updateStats('correct');
                    playSound(true);

                } else {
                    setIsCorrect(false);
                    updateStats('wrong');
                    playSound(false);

                    setWrongAnswers(prev => {
                        const newCount = (prev[onScreenQuestion.kanji] || 0) + 1;
                        return { ...prev, [onScreenQuestion.kanji]: newCount };
                    });
                    setQuizList((prevQuizList) => {
                        const updatedQuizList = [...prevQuizList, onScreenQuestion];

                        return updatedQuizList;
                    });

                    // Show context if this is the first wrong answer
                    if (!wrongAnswers[onScreenQuestion.kanji]) {
                        setShowContextHint(true);
                    }
                }
            }
        }
    };

    const updateUserProgressAfterLesson = (currentKanji) => {
        const callable = createCallableFunction('updateUserProgressAfterLessonQuiz');
        return callable({ currentKanji })
            .then((result) => {
                console.log('Review updated successfully:', result.data);
                return result.data;
            })
            .catch((error) => {
                console.error('Error updating review:', error);
                // Optionally, rethrow the error to be caught in handleReturnHome
                throw error;
            });
    };
    
    const handleInputChange = (event) => {
        let input = event.target.value.toLowerCase();
        if (quizMode !== 'english') {
            input = convertRomajiToHiragana(input, useKatakana);
        }
        setUserInput(input);
    };

    //Begin Section to handle the logic for the four buttons
    const [kanjiContextData, setKanjiContextData] = useState(null);
    const handleShowAnswer = async () => {
        if (!showingAnswer) {
            // First click: Show the answer
            setShowContextHint(false);
            setIsCorrect(false);
            setShowingAnswer(true);
    
            // Fetch kanji context data
            try {
                const data = await fetchAllKanjiData(onScreenQuestion.kanji);
                let contextData = null;
    
                if (onScreenQuestion.type.includes('kunyomi')) {
                    const kunyomiData = data.kunyomi?.vocabWords[0] || {};
                    contextData = {
                        sampleSentence: kunyomiData.sampleSentence,
                        sampleSentenceMeaning: kunyomiData.sampleSentenceMeaning,
                        vocabWord: kunyomiData.word,
                        vocabWordReading: kunyomiData.reading,
                        vocabWordMeaning: kunyomiData.meaning,
                        furiganaSentence: kunyomiData.sampleSentence ? 
                            renderSentenceWithFurigana(
                                kunyomiData.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                kunyomiData.furiganaSampleSentence,
                                kunyomiData.word,
                                true
                            ) : null
                    };
                } else if (onScreenQuestion.type.includes('onyomi')) {
                    const onyomiData = data.onyomi?.vocabWords[0] || {};
                    contextData = {
                        sampleSentence: onyomiData.sampleSentence,
                        sampleSentenceMeaning: onyomiData.sampleSentenceMeaning,
                        vocabWord: onyomiData.word,
                        vocabWordReading: onyomiData.reading,
                        vocabWordMeaning: onyomiData.meaning,
                        furiganaSentence: onyomiData.sampleSentence ? 
                            renderSentenceWithFurigana(
                                onyomiData.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                onyomiData.furiganaSampleSentence,
                                onyomiData.word,
                                true
                            ) : null
                    };
                } else if (onScreenQuestion.type === 'primaryMeaning') {
                    contextData = {
                        primaryMeaning: data.primaryMeaning || 'N/A',
                        secondaryMeaning: data.secondaryMeaning || 'N/A',
                    };
                }
                setKanjiContextData(contextData);
            } catch (error) {
                console.error('Error fetching kanji context data:', error);
            }
        } else {
            // Second click: Move to the next question
            setShowingAnswer(false);
            setKanjiContextData(null);
            setQuizList((prevQuizList) => {
                const updatedQuizList = [...prevQuizList.slice(1), onScreenQuestion];
                setTimeout(() => {
                    nextQuestionHandler(updatedQuizList);
                }, 700);
                return updatedQuizList;
            });
        }
    };

    const handleSkip = () => {
        setIsSkipping(true);
        setShowingAnswer(false);
        setShowContextHint(false);
        updateStats('skip');
        setQuizList(prevList => {
            const updatedQuizList = [...prevList.slice(1), onScreenQuestion];
            setTimeout(() => {
                nextQuestionHandler(updatedQuizList);
                setIsSkipping(false);
            }, 700);
            return updatedQuizList;
        });
    };

    const [dataForContextHint, setDataForContextHint] = useState(false);
    const handleShowContextHint = async () => {
        if (!showContextHint) {
            setShowContextHint(true);
    
            try {
                const data = await fetchAllKanjiData(onScreenQuestion.kanji);
                let contextData = null;
    
                if (onScreenQuestion.type.includes('kunyomi')) {
                    const kunyomiVocab = data.kunyomi?.vocabWords[0];
                    if (kunyomiVocab) {
                        const processedSentence = renderSentenceWithFurigana(
                            kunyomiVocab.sampleSentence,
                            kunyomiVocab.furiganaSampleSentence,
                            kunyomiVocab.word,
                            true
                        );
                        contextData = {
                            processedSentence
                        };
                    }
                } else if (onScreenQuestion.type.includes('onyomi')) {
                    const onyomiVocab = data.onyomi?.vocabWords[0];
                    if (onyomiVocab) {
                        const processedSentence = renderSentenceWithFurigana(
                            onyomiVocab.sampleSentence,
                            onyomiVocab.furiganaSampleSentence,
                            onyomiVocab.word,
                            true
                        );
                        contextData = {
                            processedSentence
                        };
                    }
                } else if (onScreenQuestion.type === 'primaryMeaning') {
                    contextData = {
                        processedSentence: "No hint available on Primary Meaning. You gotta know this! Good Luck!"
                    };
                }

                
    
                setDataForContextHint(contextData || { 
                    processedSentence: "No example context found" 
                });
            } catch (error) {
                console.error('Error fetching kanji context data:', error);
                setDataForContextHint({ 
                    processedSentence: "Error loading context" 
                });
            }
        } else {
            setShowContextHint(false);
            setDataForContextHint(null);
        }
    };

    useEffect(() => {
        const handleTabKey = (event) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                if (!showingAnswer && isCorrect !== false) {
                    handleShowContextHint();
                }
            }
        };
    
        window.addEventListener('keydown', handleTabKey);
        return () => window.removeEventListener('keydown', handleTabKey);
    }, [showingAnswer, isCorrect, handleShowContextHint]);

    const toggleKeyboard = () => {
        setShowKeyboard(!showKeyboard);
    };

    const [loadingHome, setLoadingHome] = useState(false);

    const handleReturnHome = async () => {
        setLoadingHome(true);
        
        logEvent(analytics, 'quiz_completed', {
            quiz_type: 'Kanji Lesson Quiz'
        });
        
        if (progressUpdatePromise.current) {
            try {
                await progressUpdatePromise.current;
            } catch (error) {
                console.error('Error waiting for progress update:', error);
                navigate('/error', { state: { errorMessage: 'An error occurred while updating your progress.' } });
                return;
            }
        }
        navigate('/home', { state: { previousPath: '/quiz/' } });
    };

    const updateStats = (type) => {
        setStats(prevStats => {
            const newStats = {
                ...prevStats,
                [type]: prevStats[type] + 1
            };

            const totalAttempts = newStats.correct + newStats.wrong;
            const accuracy = totalAttempts > 0
                ? Math.round((newStats.correct / totalAttempts) * 100)
                : 0;

            return {
                ...newStats,
                accuracy
            };
        });

        setHighlightedStat(type);

        setTimeout(() => {
            setHighlightedStat(null);
        }, 2000);
    };

    const toggleSound = () => {
        setIsSoundOn(!isSoundOn);
    };

    const playSound = (isCorrect) => {
        if (isSoundOn) {
            if (isCorrect) {
                correctAudio.current.play();
            } else {
                incorrectAudio.current.play();
            }
        }
    };

    const isLevelOneKanji = Object.values(kanjiData).some(kanji => kanji.level === "1");

    return (
        <div className="bg-mainBackgroundColor min-h-screen flex flex-col font-noto-sans-jp">
            <Navbar user={user} currentPath={location.pathname} subscriptionStatus={subscriptionStatus} />
            <div className="flex-grow p-4 sm:p-6 md:p-8 lg:p-10 relative">
                <div className={`w-full sm:max-w-full md:max-w-4xl lg:max-w-6xl mx-auto rounded-lg shadow-blueBoxShadow p-4 sm:p-6 md:p-8 lg:p-10 transition-colors duration-500 min-h-[50vh] ${isCorrect ? 'bg-green-100' :
                        isCorrect === false ? 'bg-red-100' :
                            isSkipping ? 'bg-yellow-100' :
                                'bg-white'
                    } relative`}>
                    <BiHelpCircle 
                        className="absolute top-2 left-2 text-2xl text-darkBlueColor cursor-pointer transition-transform hover:scale-110" 
                        onClick={toggleTooltip}
                    />
                    
                    {showTooltip && (
                        <div ref={tooltipRef} className="absolute top-10 left-2 bg-red-100 border-2 border-red-500 rounded-lg p-4 shadow-lg z-50 max-w-xs">
                            <BiXCircle 
                                className="absolute top-2 right-2 text-xl text-red-500 cursor-pointer"
                                onClick={toggleTooltip}
                            />
                            <h3 className="text-red-700 font-bold mb-2">Quiz Tips</h3>
                            <ul className="text-red-600 text-sm list-disc list-inside">
                                <li>Press Enter to check your answer. Enter again to continue to the next question.</li>
                                <li>Use the "Show Context Hint" for a helpful clue.</li>
                                <li>"Show Answer" reveals the correct response, but the question will be marked as incorrect.</li>
                                <li>Skip difficult questions to revisit later.</li>
                                <li>Toggle sound for audio feedback.</li>
                            </ul>
                        </div>
                    )}
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <ClipLoader size={50} color={"#014156"} loading={loading} />
                        </div>
                    ) : (
                        <div className="text-center relative">
                            <div className="absolute top-0 right-0 flex items-center space-x-2 p-2 bg-white rounded-lg shadow-md">
                                <img
                                    src={isSoundOn ? sound_on : sound_off}
                                    alt={isSoundOn ? "Sound On" : "Sound Off"}
                                    className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 cursor-pointer"
                                    title={isSoundOn ? "Toggle Sound Off" : "Toggle Sound On"}
                                    onClick={toggleSound}
                                />
                                <span></span>
                                <img
                                    src={correct}
                                    alt="Correct"
                                    className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
                                    title="Number of Correct Responses"
                                />
                                <span
                                    className={`text-sm sm:text-base md:text-lg font-bold transition-colors duration-300 ${highlightedStat === 'correct' ? 'text-green-500' : ''
                                        }`}
                                    title="Number of Correct Responses"
                                >
                                    {stats.correct}
                                </span>
                                <img
                                    src={wrong}
                                    alt="Wrong"
                                    className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
                                    title="Number of Incorrect Responses"
                                />
                                <span
                                    className={`text-sm sm:text-base md:text-lg font-bold transition-colors duration-300 ${highlightedStat === 'wrong' ? 'text-red-500' : ''
                                        }`}
                                    title="Number of Incorrect Responses"
                                >
                                    {stats.wrong}
                                </span>
                                <img
                                    src={accuracy}
                                    alt="Accuracy"
                                    className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
                                    title="Total Session Accuracy"
                                />
                                <span
                                    className="text-sm sm:text-base md:text-lg font-bold text-black"
                                    title="Total Session Accuracy"
                                >
                                    {stats.accuracy}%
                                </span>
                                <img
                                    src={skip}
                                    alt="Skip"
                                    className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
                                    title="Number of Questions Skipped"
                                />
                                <span
                                    className={`text-sm sm:text-base md:text-lg font-bold transition-colors duration-300 ${highlightedStat === 'skip' ? 'text-yellow-500' : ''
                                        }`}
                                    title="Number of Questions Skipped"
                                >
                                    {stats.skip}
                                </span>
                            </div>

                            
                            <div className="flex items-center justify-center h-48 sm:h-48 md:h-64 lg:h-80 mb-2 sm:mb-4 md:mb-6 lg:mb-8">
                                
                                <div className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-medium">
                                    {onScreenQuestion?.kanji}
                                </div>
                            </div>
                            {showContextHint && dataForContextHint ? (
                                    <div className="flex flex-col space-y-2 p-4 mb-4">
                                        <div className="mt-2 text-left">
                                            <div className="bg-lightBlueBackground p-6 rounded-lg shadow-md space-y-4 w-full">
                                                <div className="space-y-2">
                                                    <p className="text-darkBlueColor text-sm">Sample Sentence</p>
                                                    <div className="text-2xl font-medium text-lightBlueText">
                                                        {dataForContextHint.processedSentence}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            ) : showingAnswer ? (
                                kanjiContextData && onScreenQuestion.type !== 'primaryMeaning' && (
                                    <div className="flex flex-col space-y-2 p-4 mb-4">
                                        <div className="mt-2 text-left">
                                            {(onScreenQuestion.type.includes('kunyomi') || onScreenQuestion.type.includes('onyomi')) && (
                                                <div className={`bg-${onScreenQuestion.type.includes('kunyomi') ? 'orange-100' : 'lightBlueBackground'} p-6 rounded-lg shadow-md space-y-4 w-full`}>
                                                    <h3 className={`text-xl font-bold ${onScreenQuestion.type.includes('kunyomi') ? 'text-orange-800' : 'text-lightBlueText'}`}>
                                                        {onScreenQuestion.type.includes('kunyomi') ? "Kun'yomi" : "On'yomi"}
                                                    </h3>
                                                    {kanjiContextData.furiganaSentence && (
                                                        <div className="space-y-2 mt-4">
                                                            <p className={`${onScreenQuestion.type.includes('kunyomi') ? 'text-orange-600' : 'text-darkBlueColor'} text-sm`}>Sample Sentence</p>
                                                            <div className={`text-2xl font-medium ${onScreenQuestion.type.includes('kunyomi') ? 'text-orange-900' : 'text-lightBlueText'}`}>
                                                                {kanjiContextData.furiganaSentence}
                                                            </div>
                                                            <div className={`${onScreenQuestion.type.includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'} text-xl`}>
                                                                {kanjiContextData.sampleSentenceMeaning}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            ) : null}
                            <div className={`text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold p-3 sm:p-4 md:p-5 lg:p-6 rounded-t-lg transition-colors duration-500 ${isCorrect ? 'bg-green-200 text-green-800' :
                                    isCorrect === false ? 'bg-red-200 text-red-800' :
                                        isSkipping ? 'bg-yellow-200 text-yellow-800' :
                                            'bg-lightBlueBackground text-lightBlueText'
                                }`}>
                                <span className="whitespace-nowrap">
                                {onScreenQuestion?.type === 'primaryMeaning'
                                    ? 'Enter Kanji Meaning'
                                    : onScreenQuestion?.type === 'kunyomi'
                                        ? 'Enter Kunyomi Reading'
                                        : onScreenQuestion?.type === 'onyomi'
                                            ? 'Enter Onyomi Reading'
                                            : 'Enter Onyomi Reading'}
                                </span>
                            </div>
                            <div className={`border-4 p-2 sm:p-4 md:p-6 lg:p-8 mx-auto rounded-b-lg w-full transition-colors duration-500 ${isCorrect ? 'bg-white border-green-300' :
                                    isCorrect === false ? 'bg-white border-red-300' :
                                        isSkipping ? 'bg-white border-yellow-300' :
                                            'bg-white border-lightBlueBackground'
                                }`}>
                                {showingAnswer ? (
                                    <div className="flex flex-col items-center">
                                        <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-4">
                                            Correct Answer: {correctAnswer.join(', ')}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="relative">
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            value={userInput}
                                            onChange={handleInputChange}
                                            onKeyDown={handleQuestionSubmission}
                                            className={`w-full p-3 sm:p-5 md:p-5 lg:p-5 text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold rounded-lg text-center placeholder-center focus:outline-none h-14 sm:h-16 md:h-18 lg:h-20 ${isCorrect ? 'bg-green-50 text-black' :
                                                    isCorrect === false ? 'bg-red-50 text-black' :
                                                        'bg-white text-black'
                                                }`}
                                                placeholder={
                                                    onScreenQuestion?.inputType === 'english' ? 'Enter Your Answer' :
                                                    onScreenQuestion?.type.includes('kunyomi') ? 'くんよみ' :
                                                    onScreenQuestion?.type.includes('onyomi') ? 'オンヨミ' :
                                                    '答え'
                                                }
                                            style={{ textAlign: 'center' }}
                                        />
                                        <button
                                            onClick={() => handleQuestionSubmission({ key: 'Enter' })}
                                            className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-white font-bold py-2 sm:py-2.5 md:py-3 lg:py-3.5 px-3 sm:px-3.5 md:px-4 lg:px-4.5 rounded-lg transition duration-300 ease-in-out flex items-center justify-center ${isCorrect ? 'bg-green-500 hover:bg-green-600' :
                                                    isCorrect === false ? 'bg-red-500 hover:bg-red-600' :
                                                        isSkipping ? 'bg-yellow-500 hover:bg-yellow-600' :
                                                            'bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover'
                                                }`}
                                            title="Press Enter or click here to submit"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-9 lg:w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 mt-3 sm:mt-4 md:mt-6 lg:mt-8">
                                <button
                                    onClick={handleShowContextHint}
                                    disabled={showingAnswer || isCorrect === false}
                                    className={`bg-white border border-darkBlueColor text-darkBlueColor font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 group ${
                                        showingAnswer || isCorrect === false
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:bg-darkBlueColor hover:text-white'
                                    }`}
                                >
                                    <div className="flex items-center justify-center gap-1">
                                        <MdKeyboardTab className={`${showingAnswer || isCorrect === false 
                                            ? 'text-darkBlueColor'
                                            : 'group-hover:text-white'}`} /> 
                                        <span>Show Context Hint</span>
                                    </div>
                                </button>
                                <button
                                    onClick={handleShowAnswer}
                                    disabled={(isCorrect === false && !showingAnswer) || isCorrect === true}
                                    className={`font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${
                                        showingAnswer
                                        ? 'bg-darkBlueColor text-white'
                                        : (isCorrect === false && !showingAnswer) || isCorrect === true
                                        ? 'bg-white border border-darkBlueColor text-darkBlueColor opacity-50 cursor-not-allowed'
                                        : 'bg-white border border-darkBlueColor text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
                                    }`}
                                >
                                    {showingAnswer ? "Next Question" : "Show Answer"}
                                </button>
                                <button
                                    onClick={handleSkip}
                                    disabled={isSkipping || showingAnswer || isCorrect === false}
                                    className={`font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${
                                        isSkipping || showingAnswer || isCorrect === false
                                        ? 'bg-white border border-darkBlueColor text-darkBlueColor opacity-50 cursor-not-allowed'
                                        : 'bg-white border border-darkBlueColor text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
                                    }`}
                                >
                                    Skip
                                </button>
                                <button
                                    onClick={toggleKeyboard}
                                    disabled={showingAnswer || isCorrect === false}
                                    className={`bg-white border border-darkBlueColor text-darkBlueColor font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${
                                        showingAnswer || isCorrect === false
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:bg-darkBlueColor hover:text-white'
                                    }`}
                                >
                                    {showKeyboard ? 'Hide Keyboard' : 'Show Keyboard'}
                                </button>
                                <button
                                    onClick={() => setUseKatakana(!useKatakana)}
                                    disabled={showingAnswer || isCorrect === false}
                                    className={`${useKatakana ? 'bg-darkBlueColor text-white' : 'bg-white border border-darkBlueColor text-darkBlueColor'
                                        } ${
                                            showingAnswer || isCorrect === false
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-darkBlueColor hover:text-white'
                                        } font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 transition-colors duration-300`}
                                >
                                    {useKatakana ? 'Switch to Hiragana' : 'Switch to Katakana'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showKeyboard && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg max-w-3xl max-h-full overflow-auto">
                        <img
                            src={hiraganaKeyboardImage}
                            alt="Hiragana Keyboard Mapping"
                            className="w-full h-auto"
                        />
                        <button
                            onClick={toggleKeyboard}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            {quizFinished && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="text-center p-6 sm:p-8 md:p-10 lg:p-12 bg-white border-4 border-darkBlueColor rounded-2xl shadow-2xl max-w-3xl mx-auto">
                        <img 
                            src={oniKanjiLessonCompletedImage} 
                            alt="Lesson Completed" 
                            className="w-48 sm:w-56 md:w-64 lg:w-72 mx-auto mb-6"
                        />
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-darkBlueColor">
                            Quiz Completed
                        </h2>
                        <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-8 text-gray-700">
                            お疲れ様です。
                        </p>
                        {isLevelOneKanji && (
                            <div className="text-left text-gray-600 mb-8 space-y-4">
                                <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold">
                                    Congratulations on completing your first few Kanji lessons!
                                </p>
                                <ul className="list-disc list-inside space-y-2">
                                    <li className="text-sm sm:text-base md:text-lg lg:text-xl">
                                        In about 15 minutes, you'll receive review questions on your dashboard.
                                    </li>
                                    <li className="text-sm sm:text-base md:text-lg lg:text-xl">
                                        These will include various new question types, new vocabulary, and new context sentences.
                                    </li>
                                    <li className="text-sm sm:text-base md:text-lg lg:text-xl">
                                        Don't worry if you were not able to get everything right on the first try. Try to focus on using the context for recognition as opposed to rote memorization.
                                    </li>
                                    <li className="text-sm sm:text-base md:text-lg lg:text-xl">
                                        Join our <a href="https://discord.gg/7TEG9cqZTP" className="text-blue-500 underline">Discord</a> community for questions or feedback.
                                    </li>
                                </ul>
                            </div>
                        )}
                        <button
                            onClick={handleReturnHome}
                            className="bg-white border-2 border-darkBlueColor text-darkBlueColor font-bold py-3 sm:py-4 md:py-5 lg:py-6 px-6 sm:px-8 md:px-10 lg:px-12 rounded-lg shadow-lg hover:bg-darkBlueColor hover:text-white transition-all duration-300 transform hover:scale-105"
                        >
                            {loadingHome ? (
                                <ClipLoader size={24} color={"#014156"} loading={loadingHome} />
                            ) : (
                                "Return to Home"
                            )}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default KanjiLessonQuiz;
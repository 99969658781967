import { useQuery } from '@tanstack/react-query';
import { getFirestore, doc, collection, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useContentFetch = (title, position = 1) => {
    const navigate = useNavigate();
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);

    useEffect(() => {
        if (title && position !== undefined) {
            setIsQueryEnabled(true);
        }
    }, [title, position]);

    return useQuery({
        queryKey: ['content', title, position],
        queryFn: async () => {
            try {
                if (!title || typeof title !== 'string') {
                    throw new Error('Invalid title');
                }
                if (typeof position !== 'number') {
                    throw new Error('Invalid position');
                }

                const db = getFirestore();
                const contentDoc = doc(db, 'immersionContent', title);
                const positionDoc = doc(collection(contentDoc, 'content'), position.toString());

                const snapshot = await getDoc(positionDoc);

                if (!snapshot.exists()) {
                    throw new Error('Content not found');
                }

                const data = snapshot.data();

                if (!data.text) {
                    throw new Error('Invalid content structure');
                }

                return {
                    content: data.text,
                    title: title,
                    position: position
                };

            } catch (error) {
                console.error('Content fetch error:', error);
                navigate('/error');
                throw error;
            }
        },
        enabled: isQueryEnabled,
        staleTime: 24 * 60 * 60 * 1000,
        cacheTime: 7 * 24 * 60 * 60 * 1000,
        retry: 2,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    });
};

import React from 'react';
import { BiBook, BiFontSize, BiHelpCircle, BiHome, BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from "react-icons/bi";

const ReaderNavigation = ({ currentPage, totalPages, onFirstPage, onPreviousPage, onNextPage, onLastPage, onCycleFontSize, onNavigateHome, isSaving, onHelpClick }) => (
  <nav className="px-2 sm:px-4 py-2 sm:py-3">
    <div className="flex flex-wrap justify-between items-center">
      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onFirstPage} // go to first page
      >
        <BiFirstPage className="text-xl sm:text-2xl" />
      </button>

      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onPreviousPage}
      >
        <BiChevronLeft className="text-xl sm:text-2xl" />
      </button>

      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onCycleFontSize} // Cycle through font sizes
      >
        <BiFontSize className="text-xl sm:text-2xl" />
      </button>

      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onNavigateHome}
        disabled={isSaving} // Disable button while saving
      >
        {isSaving ? 'Saving...' : <BiHome className="text-xl sm:text-2xl" />}
      </button>

      <div className="flex items-center gap-1">
        <BiBook className="text-xl sm:text-2xl text-gray-600" />
        <span className="text-gray-600 text-sm sm:text-xl">
          Page {currentPage + 1} of {totalPages}
        </span>
      </div>

      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onHelpClick}
      >
        <BiHelpCircle className="text-xl sm:text-2xl" />
      </button>

      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onNextPage}
      >
        <BiChevronRight className="text-xl sm:text-2xl" />
      </button>

      <button
        className="p-1 sm:p-2 text-gray-600 hover:text-gray-900 transition-colors"
        onClick={onLastPage} // go to last page
      >
        <BiLastPage className="text-xl sm:text-2xl" />
      </button>
    </div>
  </nav>
);

export default ReaderNavigation;
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotSubscribedToReaderDisplay({ isOpen, onClose }) {
    const navigate = useNavigate();

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Overlay */}
            <div 
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                onClick={onClose}
            />
            
            {/* Modal Container */}
            <div className="relative w-[95%] max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl mx-auto z-50">
                <div className="relative bg-white rounded-lg shadow-xl transform transition-all">
                    {/* Content */}
                    <div className="p-6 sm:p-8">
                        <div className="text-center">
                            <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4">
                                Subscription Required Content
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm sm:text-base text-gray-600 mb-2">
                                    We're Sorry 😅 - We have starving artists 🎨, writers ✍️, and developers 👩‍💻 who need to be fed
                                </p>
                                <p className="text-sm sm:text-base text-gray-600 mb-2">
                                    We're working hard to bring you the best manga and light novels, but we need your support to keep us going.
                                </p>
                                <p className="text-sm sm:text-base text-gray-600 mb-6">
                                    Feel free to poke around. Try out the reader free on us. 
                                </p>
                            </div>
                        </div>
                        
                        {/* Buttons */}
                        <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row-reverse sm:justify-center gap-6">
                            <button
                                type="button"
                                className="w-full sm:w-auto px-6 py-3 bg-logoColor hover:bg-logoColorHover text-white rounded-md transition-colors duration-200 text-sm sm:text-base font-semibold"
                                onClick={() => navigate('/pricing')}
                            >
                                Upgrade Now
                            </button>
                            <button
                                type="button"
                                className="w-full sm:w-auto px-6 py-3 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md transition-colors duration-200 text-sm sm:text-base font-semibold"
                                onClick={onClose}
                            >
                                Read One Free Chapter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotSubscribedToReaderDisplay;

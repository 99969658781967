import React, { useEffect, useState, useCallback } from 'react';
import { useUser } from '../components/utils/UserContext';
import Navbar from '../components/navBar';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase'; 
import { ClipLoader } from 'react-spinners';
import { logEvent } from "firebase/analytics";
import { analytics } from '../config/firebase';

function AllKanji() {
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(() => {
        const saved = sessionStorage.getItem('allKanjiCurrentPage');
        return saved ? parseInt(saved, 10) : 1;
    });
    const levelsPerPage = 5;
    const [totalLevels, setTotalLevels] = useState(0);
    const [isLoadingLevels, setIsLoadingLevels] = useState(true);

    // Calculate total pages
    const totalPages = Math.ceil(totalLevels / levelsPerPage);

    // Calculate levels to show based on current page
    const calculateLevelsToShow = useCallback(() => {
        const startLevel = (currentPage - 1) * levelsPerPage + 1;
        const endLevel = Math.min(startLevel + levelsPerPage - 1, totalLevels);
        return Array.from({ length: endLevel - startLevel + 1 }, (_, i) => (startLevel + i).toString());
    }, [currentPage, levelsPerPage, totalLevels]);

    const [kanjiLevelsToShow, setKanjiLevelsToShow] = useState([]);

    const [showContentBlock, setShowContentBlock] = useState(false);

    useEffect(() => {
        document.title = "OniKanji - All Kanji";
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [user, userLoading, navigate]);

    // Fetch total number of levels
    useEffect(() => {
        const fetchTotalLevels = async () => {
            setIsLoadingLevels(true);
            try {
                const levelsSnapshot = await getDocs(query(collection(db, "allKanji")));
                const levelsSet = new Set();
                levelsSnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data && data.level) {
                        levelsSet.add(parseInt(data.level));
                    }
                });
                const maxLevel = Math.max(...levelsSet);
                setTotalLevels(maxLevel);
            } catch (error) {
                console.error("Error fetching total levels:", error);
            } finally {
                setIsLoadingLevels(false);
            }
        };
        fetchTotalLevels();
    }, []);

    // Update kanjiLevelsToShow when currentPage or totalLevels change
    useEffect(() => {
        setKanjiLevelsToShow(calculateLevelsToShow());
    }, [currentPage, totalLevels, calculateLevelsToShow]);

    // Save currentPage to sessionStorage
    useEffect(() => {
        sessionStorage.setItem('allKanjiCurrentPage', currentPage.toString());
    }, [currentPage]);

    const fetchAllKanjiDataByLevel = async ({ queryKey }) => {
        const [_key, { kanjiLevelsToShow }] = queryKey;
        // console.log("Fetching Kanji data for levels:", kanjiLevelsToShow);
        
        if (!Array.isArray(kanjiLevelsToShow) || kanjiLevelsToShow.length === 0) {
            console.warn('kanjiLevelsToShow is empty. Skipping query.');
            return [];
        }

        const queries = kanjiLevelsToShow.map(level => 
            query(
                collection(db, "allKanji"),
                where("level", "==", level),
                orderBy("lexicographicKanjiID")
            )
        );

        const querySnapshots = await Promise.all(queries.map(q => getDocs(q)));
        const allDocs = querySnapshots.flatMap(snapshot => snapshot.docs);
        
        return allDocs.map(doc => doc.data());
    };

    const { data: kanjiList, isLoading: loadingKanji, error } = useQuery({
        queryKey: ['kanjiData', { kanjiLevelsToShow }],
        queryFn: fetchAllKanjiDataByLevel,
        enabled: kanjiLevelsToShow.length > 0 && !isLoadingLevels,
        onSuccess: (data) => console.log("Kanji data loaded successfully", data),
        onError: (error) => console.error("Error fetching Kanji data", error)
    });

    const handleNextClick = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
        if (subscriptionStatus !== 'active' && subscriptionStatus !== 'trialing') {
            setShowContentBlock(true);
            logEvent(analytics, 'Please Sub - All Kanji Page');
        }
    };

    // Wait for user and kanji data to load
    if (userLoading || isLoadingLevels || loadingKanji) {
        return (
            <div className="flex justify-center items-center h-screen bg-white">
                <ClipLoader color={"#014156"} size={50} />
            </div>
        );
    }

    if (error) {
        navigate('/error', { state: { errorMessage: `Error fetching Kanji: ${error.message}`, location: 'All Kanji Page' } });
        return null;
    }

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className='container mx-auto space-y-4 p-6'>
                {kanjiLevelsToShow.map(level => (
                    <React.Fragment key={level}>
                        <div className='flex flex-col items-center bg-lightBlueBackground text-lightBlueText p-4 rounded-lg mt-10'>
                            <div className='text-2xl font-bold'>Level {level} Kanji</div>
                            <div className='text-lg'>Joyo Kanji {(level - 1) * 20 + 1}-{level * 20}</div>
                        </div>

                        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-6'>
                            {kanjiList && kanjiList.filter(kanji => kanji.level === level.toString()).map((kanji) => (
                                <Link to={`/kanji/${kanji.kanji}`} key={kanji.kanji} className='flex flex-col items-center p-4 bg-white border rounded-lg shadow hover:bg-blue-100 transition duration-300'>
                                    <div className='text-4xl font-bold mb-2'>{kanji.kanji}</div>
                                    <div className='text-gray-600'>{kanji.primaryMeaning}</div>
                                </Link>
                            ))}
                        </div>
                    </React.Fragment>
                ))}

                {/* Pagination Buttons */}
                <div className='flex justify-between mt-6'>
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextClick}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                    >
                        Next
                    </button>
                </div>
            </div>

            {showContentBlock && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
                    <div className="bg-white p-6 md:p-8 lg:p-10 rounded shadow-lg text-center max-w-md w-full">
                        <h2 className="text-lg md:text-xl font-bold mb-4">🔒 Subscription Required Content 🔒</h2>
                        <p className="text-sm md:text-base">We're Sorry 😅 - We have starving artists 🎨, writers ✍️, and developers 👩‍💻 who need to be fed.</p>
                        <p className="mt-4 text-sm md:text-base">We are working hard to consistently bring you the best curriculum and context data as possible, but we need your support to keep us going.</p>
                        <p className="mt-4">
                            <Link to="/pricing" className="text-blue-500 underline">Check out OniKanji pricing here</Link>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AllKanji;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { BiHelpCircle, BiXCircle } from "react-icons/bi";
import { useAudioPlayback } from '../components/utils/generateAudio';
import { renderSentenceWithFurigana } from '../components/utils/furiganaSentenceBuilder';



function KanjiNewLesson() {

    const location = useLocation();
    const newLessonKanjiDataFromHomePage = location.state?.kanjiData;

    const [kanjiList, setKanjiList] = useState([]);
    const [kanjiData, setKanjiData] = useState({});
    const [selectedKanji, setSelectedKanji] = useState(null);
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [trainingButtonActive, setTrainingButtonToActive] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const { handleTextToSpeech, isAudioPlaying, PulsingVolumeIcon } = useAudioPlayback();

    useEffect(() => {
        document.title = "OniKanji - New Lesson";
        if (!userLoading && !user) {
            navigate('/login');
        } else if (newLessonKanjiDataFromHomePage) {
            const kanjiKeys = Object.keys(newLessonKanjiDataFromHomePage);
            setKanjiList(kanjiKeys);
            setSelectedKanji(kanjiKeys[0]);
            setKanjiData(newLessonKanjiDataFromHomePage);
            setLoading(false);
        }
    }, [user, userLoading, newLessonKanjiDataFromHomePage, navigate]);


    const handleKanjiClick = (kanji) => {
        setSelectedKanji(kanji);
        
        // Check if the screen width is below a certain threshold (e.g., 768px for mobile)
        if (window.innerWidth < 768) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const navigateNext = () => {
            const currentIndex = kanjiList.indexOf(selectedKanji);
            if (currentIndex < kanjiList.length - 1) {
                setSelectedKanji(kanjiList[currentIndex + 1]);
            } else {
                setTrainingButtonToActive(true);
            }
        };

        const navigatePrevious = () => {
            const currentIndex = kanjiList.indexOf(selectedKanji);
            setTrainingButtonToActive(false);
            if (currentIndex > 0) {
                setSelectedKanji(kanjiList[currentIndex - 1]);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                navigateNext();
            } else if (event.key === 'ArrowLeft') {
                navigatePrevious();
            }
        };

        const checkLastKanjiAndExamples = () => {
            const currentIndex = kanjiList.indexOf(selectedKanji);
            if (currentIndex === kanjiList.length - 1) {
                setTrainingButtonToActive(true);
            } else {
                setTrainingButtonToActive(false);
            }
        };

        checkLastKanjiAndExamples();

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [kanjiList, selectedKanji]);


    const handleQuizClick = () => {
        navigate(`/quiz/`, { state: { kanjiData: newLessonKanjiDataFromHomePage } });
    };

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        if (showTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10 relative">
                    <BiHelpCircle
                        className="absolute top-2 right-2 text-2xl text-gray-500 cursor-pointer transition-transform hover:scale-110"
                        onClick={toggleTooltip}
                    />

                    {showTooltip && (
                        <div ref={tooltipRef} className="absolute top-10 right-2 bg-red-100 border-2 border-red-500 rounded-lg p-4 shadow-lg z-10 max-w-xs">
                            <BiXCircle
                                className="absolute top-2 right-2 text-xl text-red-500 cursor-pointer"
                                onClick={toggleTooltip}
                            />
                            <h3 className="text-red-700 font-bold mb-2">Helpful Tips</h3>
                            <ul className="text-red-600 text-sm list-disc list-inside">
                                <li>Use keyboard arrow keys to quickly navigate between kanji</li>
                                <li>Click the speaker icon to hear pronunciations</li>
                                <li>Training Grounds unlocks after viewing all kanji</li>
                                <li>After completing the Training Grounds Quiz, these kanji will be added to your Review Queue</li>
                            </ul>
                        </div>
                    )}

                    <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                        <div className="text-6xl sm:text-6xl font-bold text-black text-center m-4">{selectedKanji}</div>
                        <div className="text-center px-2 sm:px-4 py-2 sm:py-3 text-xl sm:text-3xl font-semibold">
                            {kanjiData[selectedKanji]?.primaryMeaning || 'No meaning available'}
                        </div>

                        {kanjiData[selectedKanji]?.secondaryMeaning && (
                            <div className="text-center px-2 sm:px-4 py-2 sm:py-3 text-xl sm:text-3xl font-semibold">
                                {kanjiData[selectedKanji].secondaryMeaning.split(',').map(meaning => meaning.trim().charAt(0).toUpperCase() + meaning.trim().slice(1)).join(', ')}
                            </div>
                        )}

                        <div className="flex w-full justify-center mt-4 space-x-4">
                            <div className={`text-center p-2 sm:p-3 text-sm sm:text-base rounded flex-1 ${kanjiData[selectedKanji]?.onyomi?.readings?.length ? 'bg-lightBlueBackground text-lightBlueText' : 'bg-gray-200'}`}>
                                <div className="font-semibold mb-2">On'yomi Readings</div>
                                {kanjiData[selectedKanji]?.onyomi?.readings?.map((reading, index) => (
                                    <div key={index} className="text-3xl sm:text-3xl mt-1 font-medium">
                                        <span className="inline-block">
                                            {reading}
                                            <div className="h-0.5 bg-lightBlueText mt-1"></div>
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={`text-center p-2 sm:p-3 text-sm sm:text-base rounded flex-1 ${kanjiData[selectedKanji]?.kunyomi?.readings?.length ? 'bg-orange-100 text-orange-800' : 'bg-gray-200'}`}>
                                <div className="font-semibold mb-2">Kun'yomi Readings</div>
                                {kanjiData[selectedKanji]?.kunyomi?.readings?.map((reading, index) => (
                                    <div key={index} className="text-3xl sm:text-3xl mt-1 font-medium">
                                        <span className="inline-block">
                                            {reading}
                                            <div className="h-0.5 bg-orange-800 mt-1"></div>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                        <div className="w-full p-4 rounded-lg">
                            <div className="text-xl sm:text-2xl font-semibold text-black">Vocabulary & Examples</div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                            {/* On'yomi Card Column */}
                            <div className="space-y-6">
                                {kanjiData[selectedKanji]?.onyomi?.vocabWords?.length > 0 ? (
                                    kanjiData[selectedKanji].onyomi.vocabWords.map((vocabWord, index) => (
                                        <div key={`onyomi-${index}`} className="bg-lightBlueBackground p-6 rounded-lg shadow-md space-y-4 w-full">
                                            <div className="space-y-2">
                                                <p className="text-darkBlueColor text-sm">Vocabulary Word</p>
                                                <div className="flex flex-row items-start">
                                                    <div className="text-3xl font-medium text-lightBlueText min-w-[40%] shrink-0">{vocabWord.word.replace(/\/\/.*?\/\//g, '')}</div>
                                                    <div className="w-[60%] flex justify-end">
                                                        <p className="text-xl text-darkBlueColor ml-0 md:ml-4 break-words">{vocabWord.meaning.split(',').map(part => part.trim().charAt(0).toUpperCase() + part.trim().slice(1)).join(', ')}</p>
                                                    </div>
                                                </div>
                                                <p className="text-darkBlueColor text-xl">{vocabWord.reading}</p>
                                            </div>
                                            {vocabWord.sampleSentence && (
                                                <div className="space-y-2 mt-4">
                                                    <p className="text-darkBlueColor text-sm">Sample Sentence</p>
                                                    <div className="text-lg text-darkBlueColor mb-2">
                                                        <span
                                                            className={`text-darkBlueColor text-2xl mr-2 ${isAudioPlaying ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                                                                }`}
                                                            onClick={() => {
                                                                if (!isAudioPlaying) {
                                                                    handleTextToSpeech(
                                                                        vocabWord.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                                                        vocabWord.furiganaSampleSentence,
                                                                        vocabWord.word
                                                                    );
                                                                }
                                                            }}
                                                            title={isAudioPlaying ? 'Audio is currently playing' : 'Play audio'}
                                                        >
                                                            <PulsingVolumeIcon isPlaying={isAudioPlaying} />
                                                        </span>
                                                        {/* Render the sentence with furigana using React elements */}
                                                        <span className="text-2xl font-medium text-lightBlueText leading-[3rem]">
                                                            {renderSentenceWithFurigana(
                                                                vocabWord.sampleSentence,
                                                                vocabWord.furiganaSampleSentence,
                                                                vocabWord.word
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="text-darkBlueColor text-xl">{vocabWord.sampleSentenceMeaning}</div>
                                                    {vocabWord.sampleSentence.match(/\(.*?\)/) && (
                                                        <div className="text-darkBlueColor text-sm italic mt-2">
                                                            {vocabWord.sampleSentence.match(/\(.*?\)/)[0]}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    // Optionally, add a placeholder or leave empty
                                    null
                                )}
                            </div>

                            {/* Kun'yomi Card Column */}
                            <div className="space-y-6">
                                {kanjiData[selectedKanji]?.kunyomi?.vocabWords?.length > 0 ? (
                                    kanjiData[selectedKanji].kunyomi.vocabWords.map((vocabWord, index) => (
                                        <div key={`kunyomi-${index}`} className="bg-orange-100 p-6 rounded-lg shadow-md space-y-4 w-full">
                                            <div className="space-y-2">
                                                <p className="text-orange-600 text-sm">Vocabulary Word</p>
                                                <div className="flex flex-row items-start">
                                                <div className="text-3xl font-medium text-orange-900 min-w-[40%] shrink-0">{vocabWord.word.replace(/\/\/.*?\/\//g, '')}</div>
                                                <div className="w-[60%] flex justify-end">
                                                        <p className="text-xl text-orange-700 ml-0 md:ml-4 break-words">{vocabWord.meaning.split(',').map(part => part.trim().charAt(0).toUpperCase() + part.trim().slice(1)).join(', ')}</p>
                                                    </div>
                                                </div>
                                                <p className="text-orange-700 text-xl">{vocabWord.reading}</p>
                                            </div>
                                            {vocabWord.sampleSentence && (
                                                <div className="space-y-2 mt-4">
                                                    <p className="text-orange-600 text-sm">Sample Sentence</p>
                                                    <div className="text-lg text-orange-700 mb-2">
                                                        <span
                                                            className={`text-orange-700 text-2xl mr-2 ${isAudioPlaying ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                                                                }`}
                                                            onClick={() => {
                                                                if (!isAudioPlaying) {
                                                                    handleTextToSpeech(
                                                                        vocabWord.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                                                        vocabWord.furiganaSampleSentence,
                                                                        vocabWord.word
                                                                    );
                                                                }
                                                            }}
                                                            title={isAudioPlaying ? 'Audio is currently playing' : 'Play audio'}
                                                        >
                                                            <PulsingVolumeIcon isPlaying={isAudioPlaying} />
                                                        </span>
                                                        {/* Render the sentence with furigana using React elements */}
                                                        <span className="text-2xl font-medium text-orange-900 leading-[3rem]">
                                                            {renderSentenceWithFurigana(
                                                                vocabWord.sampleSentence,
                                                                vocabWord.furiganaSampleSentence,
                                                                vocabWord.word
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="text-orange-700 text-xl">{vocabWord.sampleSentenceMeaning}</div>
                                                    {vocabWord.sampleSentence.match(/\(.*?\)/) && (
                                                        <div className="text-orange-600 text-sm italic mt-2">
                                                            {vocabWord.sampleSentence.match(/\(.*?\)/)[0]}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (

                                    null
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="max-w-screen-lg w-full mx-auto mt-6 sm:mt-10">
                    <h2 className="text-xl font-bold text-center p-4">Upcoming Kanji Battles</h2>
                    <div className="flex flex-wrap justify-center space-x-4">
                        {kanjiList.map((kanji, index) => (
                            <div
                                key={index}
                                className={`text-2xl p-4 m-2 rounded-lg shadow-blueBoxShadow flex items-center justify-center cursor-pointer ${selectedKanji === kanji ? 'bg-lightBlueBackground text-darkBlueColor' : 'bg-gray-200 text-black'}`}
                                onClick={() => handleKanjiClick(kanji)}
                            >
                                {kanji}
                            </div>
                        ))}
                        <div
                            className={`text-2xl p-4 m-2 rounded-lg shadow-md flex items-center justify-center cursor-pointer ${trainingButtonActive
                                ? "bg-green-500 hover:bg-green-600 animate-pulse"
                                : "bg-gray-300 text-gray-500 cursor-not-allowed"
                                }`}
                            onClick={trainingButtonActive ? handleQuizClick : undefined}
                        >
                            Training Grounds
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default KanjiNewLesson;
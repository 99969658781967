import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { getFirestore, collection, getDoc, doc } from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAllKanjiData } from '../components/utils/fetchKanjiData';
import { fetchAllLeaderboards } from '../components/utils/fetchLeaderboard';
import { createCallableFunction } from '../config/firebase';

import DashboardSummary from '../components/dashboardSummary';
import UpcomingReviewsScheduleDisplay from '../components/upcomingReviewsScheduleDisplay';
import LevelProgressSection from '../components/levelProgressSection';
import KanjiStatusCircles from '../components/kanjiQueStatus';
import LevelStatsChart from '../components/levelStatsChart';
import Leaderboard from '../components/leaderboard';
import VocabularyHeatmap from '../components/vocabularyHeatmap';

import { BiXCircle } from "react-icons/bi";


//footer
import Footer from '../components/footer';

//utils
import { canRefresh, setLastRefresh } from '../components/utils/refreshThrottle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function KanjiHomePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const queryClient = useQueryClient();
    const [newLessons, setNewLessons] = useState(0);
    const [currentKanji, setCurrentKanji] = useState([]);
    const [homePageLoading, setHomePageLoading] = useState(true);
    const previousPath = location.state?.previousPath;
    const shouldRefetch = useRef(
        previousPath === '/reviews' ||
        previousPath === '/reviews/' ||
        previousPath === '/quiz/' ||
        previousPath === '/level-up/' ||
        previousPath === '/level-up'
    );
    const [dataFetched, setDataFetched] = useState(false);
    const [upcomingReviews, setUpcomingReviews] = useState({});
    const [isThrottled, setIsThrottled] = useState(false);


    const fetchUserProgress = async () => {
        if (!user) return [];
        const db = getFirestore();
        const userProgressRef = collection(db, 'userProgress');
        const docRef = doc(userProgressRef, user.uid);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists() && docSnapshot.data().currentKanji) {
            return docSnapshot.data().currentKanji;
        }
        return [];
    };

    const fetchAllData = async () => {
        if (!user) return { reviews: [], stats: { kanjiDetails: [], queStatusCounts: {} }, progress: [], upcomingReviews: {}, leaderboards: {} };

        // const loadKanjiToReview = createCallableFunction('loadKanjiToReviewV3');
        const loadKanjiToReview = createCallableFunction('loadKanjiToReviewV4');
        // const loadCurrentStats = createCallableFunction('loadCurrentStats');
        const loadCurrentStats = createCallableFunction('loadUserAppHomeStatsV2');
        // const getUpcomingReviews = createCallableFunction('getUpcomingReviewsV3');
        const getUpcomingReviews = createCallableFunction('getUpcomingReviewsCounts');

        const [reviewsData, statsData, progress, upcomingReviewsData, leaderboardsData] = await Promise.all([
            loadKanjiToReview(),
            loadCurrentStats(),
            fetchUserProgress(),
            getUpcomingReviews(),
            fetchAllLeaderboards()
        ]);


        setUpcomingReviews(upcomingReviewsData.data);

        return {
            reviews: reviewsData.data.preparedQuizList,
            totalKanjiAtLevel: statsData.data.totalKanjiAtLevel,
            ascensionCount: statsData.data.ascensionCount,
            currentLevel: statsData.data.currentLevel,
            isEligibleForLevelUp: statsData.data.isEligibleForLevelUp,
            stats: {
                kanjiDetails: statsData.data.kanjiDetails,
                queStatusCounts: statsData.data.queStatusCounts
            },
            progress,
            upcomingReviews: upcomingReviewsData.data,
            leaderboards: leaderboardsData,
            levelVocabulary: {
                allVocabulary: statsData.data.levelVocabulary.allVocabulary,
            }
        };
    };

    const { data: allData, isLoading, isError, refetch } = useQuery({
        queryKey: ['allData', user?.uid],
        queryFn: fetchAllData,
        enabled: false,
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });


    const hasFetchedData = useRef(false); // Prevents multiple fetches

    const handleDataFetch = useCallback(async () => {
        if (!user) {
            navigate('/login');
            return;
        }

        const updateLastLoginDate = createCallableFunction('updateLastLoginDate');
        updateLastLoginDate()
            .then(response => { })
            .catch(error => { });

        const cachedData = queryClient.getQueryData(['allData', user.uid]);
        if (!cachedData && !canRefresh()) {
            if (!isThrottled) {
                setIsThrottled(true);
                setHomePageLoading(false);
                toast.info("Woah slow down there partner! Please wait 5 seconds before refreshing again.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    style: { background: "#3498db", color: "#ffffff" }
                });
                setTimeout(() => setIsThrottled(false), 5000);
            }
            return;
        }

        if (shouldRefetch.current) {
            queryClient.invalidateQueries(['allData', user.uid]);
            const startTime = Date.now();
            const { data: fetchedData } = await refetch();

            if (previousPath === '/reviews/' || previousPath === '/reviews' || previousPath === '/quiz' || previousPath === '/quiz/') {
                const updateUserReviewBucketsOnDemand = createCallableFunction('updateUserReviewBucketsOnDemandV3');
                try {
                    const result = await updateUserReviewBucketsOnDemand();
                    if (result.data && result.data.success) {
                        setUpcomingReviews(result.data.updatedBuckets);
                        queryClient.setQueryData(['allData', user.uid], (oldData) => ({
                            ...oldData,
                            upcomingReviews: result.data.updatedBuckets
                        }));
                    }
                } catch (error) { }
            }

            setNewLessons(fetchedData.progress.length);
            setCurrentKanji(fetchedData.progress);
            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(1500 - elapsedTime, 0);
            setTimeout(() => {
                setHomePageLoading(false);
                shouldRefetch.current = false;
            }, remainingTime);
        } else if (cachedData) {
            setNewLessons(cachedData.progress.length);
            setCurrentKanji(cachedData.progress);
            setHomePageLoading(false);
        } else {
            const startTime = Date.now();
            const { data: fetchedData } = await refetch();
            setNewLessons(fetchedData.progress.length);
            setCurrentKanji(fetchedData.progress);
            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(1500 - elapsedTime, 0);
            setTimeout(() => setHomePageLoading(false), remainingTime);
        }

        setLastRefresh();
        setDataFetched(true);
    }, [user, navigate, queryClient, refetch, isThrottled]);

    useEffect(() => {
        document.title = "OniKanji - App Home";

        if (!userLoading && !dataFetched && !hasFetchedData.current) {
            handleDataFetch();
            hasFetchedData.current = true;
        }
    }, [userLoading, dataFetched]);



    const handleNewLessonClick = async () => {
        if (newLessons === 0) return; // Prevent click if newLessons is 0

        const newLessonLimit = 4; // TODO: Make this dynamic later
        const kanjiToLearn = currentKanji.slice(0, Math.min(newLessonLimit, currentKanji.length));

        let allKanjiData = queryClient.getQueryData(['kanjiData', kanjiToLearn]);
        if (!allKanjiData) {
            allKanjiData = await fetchAllKanjiData(kanjiToLearn);
        }

        navigate('/new-lesson/', { state: { kanjiData: allKanjiData } });
    };

    const handleReviewsClick = () => {
        if (allData?.reviews?.length > 0) {
            navigate('/reviews', { state: { reviews: allData.reviews } });
        }
    };

    const [isLevelingUp, setIsLevelingUp] = useState(false);
    const handleLevelUpClick = async () => {
        if (subscriptionStatus !== "active" && allData?.currentLevel >= 3) {
            // Redirect to pricing page if subscription is not active and level is 3 or higher
            navigate('/pricing');
        } else {
            setIsLevelingUp(true);
            try {
                const updateUserLevel = createCallableFunction('updateUserLevel');
                const result = await updateUserLevel();
                if (result.data.success) {
                    navigate('/level-up/', { state: { levelStats: result.data.levelStats } });
                } else {
                    console.error('Failed to update user level:', result.data.message);
                    navigate('/error');
                }
            } catch (error) {
                console.error('Error updating user level:', error);
                navigate('/error');
            } finally {
                setIsLevelingUp(false);
            }
        }
    };

    const [activeStats, setActiveStats] = useState('accuracy');
    const [chartData, setChartData] = useState([]);

    const handleStatsSectionClick = (statType) => {
        setActiveStats(statType);
        let data;
        switch (statType) {
            case 'accuracy':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, accuracy: kanjiItem.totalAccuracy }));
                break;
            case 'speed':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, speed: parseFloat(kanjiItem.averageSpeed).toFixed(3) }));
                break;
            case 'reviewTime':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, reviewTime: kanjiItem.totalTimeToAnswer }));
                break;
            case 'currentStreak':
                data = allData?.stats?.kanjiDetails?.map(kanjiItem => ({ name: kanjiItem.kanji, currentStreak: kanjiItem.currentStreak }));
                break;
            default:
                data = [];
        }
        setChartData(data);
    };

    useEffect(() => {
        if (allData?.stats?.kanjiDetails) {
            handleStatsSectionClick('accuracy');
        }
    }, [allData]);

    const handleRefreshClick = async () => {
        const updateUserReviewBucketsOnDemand = createCallableFunction('updateUserReviewBucketsOnDemandV3');
        try {
            const result = await updateUserReviewBucketsOnDemand();
            if (result.data && result.data.success) {
                setUpcomingReviews(result.data.updatedBuckets);
                // Update the cached data
                queryClient.setQueryData(['allData', user.uid], (oldData) => ({
                    ...oldData,
                    upcomingReviews: result.data.updatedBuckets
                }));
            } else {
                console.error('Failed to update review buckets:', result.data ? result.data.message : 'Unknown error');
            }
        } catch (error) {
            console.error('Error updating review buckets:', error);
        }
    };

    function SkeletonLoader() {
        return (
            <div className="animate-pulse space-y-4 sm:space-y-6">
                {/* Guide Section */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-24 sm:h-32"></div>

                {/* DashboardSummary */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {[1, 2, 3].map((i) => (
                        <div key={i} className="bg-gray-300 p-4 rounded-lg shadow-lg h-32 sm:h-40 lg:h-48"></div>
                    ))}
                </div>

                {/* LevelProgressSection */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-48 sm:h-56 lg:h-64"></div>

                {/* KanjiStatusCircles */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-40 sm:h-48"></div>

                {/* LevelStatsChart */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-48 sm:h-56 lg:h-64"></div>

                {/* Critical Condition Kanji */}
                <div className="bg-gray-300 p-4 rounded-lg shadow-lg h-40 sm:h-48"></div>
            </div>
        );
    }

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <ToastContainer />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="w-full sm:max-w-screen-xl bg-mainBackgroundColor p-4 sm:p-6 mt-2">

                    {homePageLoading || isLoading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            {subscriptionStatus !== "active" && (
                                <div className="bg-logoColor shadow-blueBoxShadow rounded-md p-4 relative transition-all duration-300 hover:bg-lightBlueBackground group">
                                    <div className="w-full h-full bg-logoColor group-hover:bg-lightBlueText transition-colors duration-300 rounded-md"></div>
                                    <div className="space-y-2 relative z-10 p-2">
                                        <p className="text-xs sm:text-sm font-semibold text-white group-hover:text-lightBlueText transition-colors duration-300">
                                            All users have free access to the app until level 3. Some features may not be accessible to free accounts. Thank you for your support! <a href="/pricing" className="text-blue-500 underline">Upgrade to access all features</a>.
                                        </p>
                                    </div>
                                </div>
                            )}

                            <DashboardSummary
                                newLessons={newLessons}
                                reviews={allData?.reviews?.length || 0}
                                onNewLessonClick={handleNewLessonClick}
                                onReviewsClick={handleReviewsClick}
                            />


                            <div className="flex flex-col lg:flex-row gap-6 mt-6 sm:mt-10">
                                <UpcomingReviewsScheduleDisplay
                                    upcomingReviews={allData?.upcomingReviews || {}}
                                    onRefreshClick={handleRefreshClick}
                                />

                                <div className="w-full">
                                    <Leaderboard
                                        currentPage="home"
                                        totalAccuracyData={allData?.leaderboards?.userStats || []}
                                        speedRunData={allData?.leaderboards?.speedRun || []}
                                        hallOfShameData={allData?.leaderboards?.hallOfShame || []}
                                    />
                                </div>
                            </div>

                            <LevelProgressSection
                                currentLevel={allData?.currentLevel}
                                isEligibleForLevelUp={allData?.isEligibleForLevelUp}
                                isLevelingUp={isLevelingUp}
                                onLevelUpClick={handleLevelUpClick}
                                kanjiDetails={allData?.stats?.kanjiDetails}
                                subscriptionStatus={subscriptionStatus}
                            />
                            <KanjiStatusCircles queStatusCounts={allData?.stats?.queStatusCounts || {}} />

                            <LevelStatsChart
                                currentLevel={allData?.currentLevel}
                                activeStats={activeStats}
                                chartData={chartData}
                                handleStatsSectionClick={handleStatsSectionClick}
                            />

                            {/* Critical Condition Kanji */}
                            <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10 mb-16">
                                <h2 className="text-2xl sm:text-3xl font-bold text-black mb-4 sm:mb-6 sm:text-left">Critical Condition Kanji</h2>
                                <div className="text-lg mb-4">
                                    {allData?.stats?.kanjiDetails
                                        ? (allData.stats.kanjiDetails.filter(kanji => kanji.totalAccuracy > 0 && kanji.totalAccuracy < 0.5).length > 0
                                            ? "Review these kanji for some extra practice. They seem to be giving you some trouble."
                                            : "No Kanji in critical status. Keep up the hardwork!")
                                        : "Error when calculating critical status kanji"}
                                </div>
                                <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 mt-6'>
                                    {allData?.stats?.kanjiDetails
                                        ? allData.stats.kanjiDetails
                                            .filter(kanji => kanji.totalAccuracy > 0 && kanji.totalAccuracy < 0.5)
                                            .map((kanji) => (
                                                <Link to={`/kanji/${kanji.kanji}`} key={kanji.kanji} className='flex flex-col items-center p-2 bg-white border rounded-lg shadow hover:bg-darkBlueColor transition duration-300'>
                                                    <div className='text-3xl font-bold mb-1'>{kanji.kanji}</div>
                                                    <div className='text-gray-600 text-sm'>{kanji.primaryMeaning}</div>
                                                </Link>
                                            ))
                                        : <div>Error when calculating critical status kanji</div>}
                                </div>
                            </div>

                            {/* Vocabulary Heatmap */}
                            <VocabularyHeatmap 
                                vocabularyData={allData?.levelVocabulary?.allVocabulary || []}
                            />
                        </>
                    )}
                </div>
            </div>
            <Footer />

        </div>
    );
}

export default KanjiHomePage;

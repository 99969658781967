import React, { useState, useEffect } from 'react';
import { useUser } from '../components/utils/UserContext';
import { getFirestore, collection, query, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import Footer from '../components/footer';
import Navbar from '../components/navBar';
import { ClipLoader } from 'react-spinners'; // Add this import

//pricing images
import pricingFishImage from '../images/landingPageAssets/onikanji-monthly-sub.png';
import pricingBonsaiImage from '../images/landingPageAssets/onikanji-yearly-sub.png';
import pricingOniMaskImage from '../images/landingPageAssets/onikanji-lifetime-sub.png';

function OnikanjiPricing() {
    const db = getFirestore();
    const { user, loading: userLoading, subscriptionStatus, subscriptionName } = useUser();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "OniKanji - Pricing";
        const fetchProducts = async () => {
            const productsRef = collection(db, 'subscriptionPlans');
            const q = query(productsRef, where('active', '==', true));
            const querySnapshot = await getDocs(q);

            const fetchedProducts = [];
            for (const doc of querySnapshot.docs) {
                // console.log(doc.id, ' => ', doc.data());
                const productData = doc.data();
                const pricesRef = collection(doc.ref, 'prices');
                const priceQuery = query(pricesRef, where('active', '==', true));
                const priceSnap = await getDocs(priceQuery);
                const prices = [];
                priceSnap.docs.forEach((priceDoc) => {
                    // console.log(priceDoc.id, ' => ', priceDoc.data());
                    prices.push({
                        id: priceDoc.id,
                        ...priceDoc.data(),
                    });
                });
                fetchedProducts.push({ id: doc.id, ...productData, prices });
            }
            setProducts(fetchedProducts);
        };

        fetchProducts();

        // Clean up function to reset loading state when component unmounts
        return () => {
            setLoading(false);
        };
    }, [db]);

    const handleSubscribe = async (product) => {
        if (!user) {
            alert('Please log in to subscribe');
            return;
        }

        setLoading(true);

        const priceId = product.prices[0].id;

        try {
            // Determine if the product is a one-time charge or a subscription
            const mode = product.prices[0].recurring ? 'subscription' : 'payment';

            const docRef = await addDoc(collection(db, 'UserCollection', user.uid, 'checkout_sessions'), {
                price: priceId,
                success_url: `${window.location.origin}/home`,
                cancel_url: `${window.location.origin}/pricing`,
                mode: mode, // 'payment' for one-time charges, 'subscription' for subscriptions
            });

            onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                    setLoading(false);
                }
                if (url) {
                    window.location.assign(url);
                }
            });
        } catch (error) {
            console.error("Error creating checkout session:", error);
            alert("An error occurred while creating the checkout session. Please try again.");
            setLoading(false);
        }
    };

    const handleManageSubscription = () => {
        window.location.href = '/my-profile';
    };

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <div className="w-full py-12 md:py-20 px-4 md:px-8 bg-mainBackground">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-8 md:mb-12 text-center">
                        Choose Your Learning Path
                    </h2>

                    <h4 className="text-sm md:text-lg lg:text-xl font-semibold text-black mb-4 md:mb-8 text-center">
                        OniKanji is currently in Alpha production. All prices are reduced to reflect this. We are working on rapid development and improvements. Prices may change as we add more features and value after Alpha. However, all current subscribers will be grandfathered in and will not experience any price changes. Thank you for your support!
                    </h4>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 bg-blueBoxShadow rounded-lg p-4 md:p-8 w-full md:w-auto">
                        {products.map((product, index) => (
                            <div key={product.id} className={`bg-white p-8 border-2 border-gray-200 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105`}>
                                <div className="mb-6">
                                    <div className="aspect-w-16 aspect-h-9 relative">
                                        <img 
                                            src={index === 0 ? pricingFishImage : 
                                                 index === 1 ? pricingBonsaiImage : 
                                                 pricingOniMaskImage} 
                                            alt={`${product.name} Plan`} 
                                            className="w-full h-full object-contain rounded-lg" 
                                        />
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">{product.name}</h3>
                                {index === 0 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">$9.00<span className="text-lg font-normal">/month</span></p>
                                        <p className="text-lg font-normal mb-2">Billed monthly</p>
                                        <p className="text-sm text-green-600 mb-6">Most flexible option</p>
                                    </>
                                )}
                                {index === 1 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">$7.00<span className="text-lg font-normal">/month</span></p>
                                        <p className="text-2xl font-bold mb-2">$84.00<span className="text-base font-normal">/year</span></p>
                                        <p className="text-sm text-gray-600 mb-6">Annual commitment required</p>
                                    </>
                                )}
                                {index === 2 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">
                                            <span className="line-through">$290.00</span>
                                            <span className="ml-2">$190.00</span>
                                        </p>
                                        <p className="text-lg font-normal mb-2">One Time Charge</p>
                                        <p className="text-sm text-gray-600 mb-6">Limited time offer</p>
                                    </>
                                )}
                                <ul className="mb-8 flex-grow space-y-3">
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        {index === 0
                                            ? "Perfect for exploring and learning at your pace"
                                            : index === 1
                                            ? "For those who prefer annual billing"
                                            : "For dedicated lifetime learners"}
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Full access to all features
                                    </li>
                                    {index === 0 && (
                                        <>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Cancel anytime
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Most flexible option
                                            </li>
                                        </>
                                    )}
                                    {index === 1 && (
                                        <>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Save $24 annually
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Best value for committed learners
                                            </li>
                                        </>
                                    )}
                                    {index === 2 && (
                                        <>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Discord Community Role
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Exclusive early access to new features
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Limited time offer
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {subscriptionStatus === 'active' && subscriptionName === product.name ? (
                                    <button 
                                        onClick={handleManageSubscription}
                                        className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center"
                                    >
                                        Manage Subscription
                                    </button>
                                ) : (
                                    <button 
                                        onClick={() => handleSubscribe(product)}
                                        className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center flex items-center justify-center"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <ClipLoader color="#014156" size={24} />
                                        ) : (
                                            'Subscribe Now'
                                        )}
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OnikanjiPricing;

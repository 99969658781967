import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { createCallableFunction } from '../../../config/firebase';

const readerDefineKanji = createCallableFunction('readerDefineKanji');
const readerDefineVocab = createCallableFunction('readerDefineVocab');

export const useKanjiData = (kanjiList) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['kanjiData', kanjiList],
    queryFn: async () => {
      if (!kanjiList || kanjiList.length === 0) {
        throw new Error('Kanji list is required');
      }

      // First, check if this is a vocabulary word (2 or more characters)
      let vocabularyData = null;
      if (kanjiList.length >= 2) {
        try {
          const vocabResponse = await readerDefineVocab({ word: kanjiList.join('') });
          vocabularyData = {
            word: kanjiList.join(''),
            isVocabulary: true,
            ...vocabResponse.data.result
          };
        } catch (error) {
          console.error('Vocabulary lookup failed:', error);
        }
      }

      const db = getFirestore();
      const fetchCollectionData = async (collectionName, kanji) => {
        const kanjiCollection = collection(db, collectionName);
        const kanjiQuery = query(kanjiCollection, where('kanji', 'in', kanji));
        const kanjiSnap = await getDocs(kanjiQuery);
        if (kanjiSnap.empty) {
          return [];
        }
        return kanjiSnap.docs.map(doc => doc.data());
      };

      const kanjiInfo = await fetchCollectionData('allKanji', kanjiList);
      const kunyomiInfo = await fetchCollectionData('kunyomiKanjiV2', kanjiList);
      const onyomiInfo = await fetchCollectionData('onyomiKanjiV2', kanjiList);

      const results = await Promise.all(kanjiList.map(async kanji => {
        const info = kanjiInfo.find(info => info.kanji === kanji);
        const kunyomiReadings = kunyomiInfo
          .filter(info => info.kanji === kanji)
          .flatMap(info => [info.kunyomiReadingOne, info.kunyomiReadingTwo, info.kunyomiReadingThree])
          .filter(Boolean);
        const onyomiReadings = onyomiInfo
          .filter(info => info.kanji === kanji)
          .flatMap(info => [info.onyomiReadingOne, info.onyomiReadingTwo, info.onyomiReadingThree])
          .filter(Boolean);

        if (!info && !kunyomiReadings.length && !onyomiReadings.length) {
          try {
            const aiResponse = await readerDefineKanji({ kanji });
            const aiData = aiResponse.data.result;
            const newKanjiData = {
              kanji,
              kanjiInfo: {
                primaryMeaning: aiData.definition,
                JLPT: aiData.jlptLevel,
                level: 'Under Curriculum Development'
              },
              kunyomi: { readings: Array.isArray(aiData.kunyomi) ? aiData.kunyomi : [aiData.kunyomi] },
              onyomi: { readings: Array.isArray(aiData.onyomi) ? aiData.onyomi : [aiData.onyomi] },
              isAIGenerated: true
            };

            // Update the cache immediately with the new AI data
            queryClient.setQueryData(['kanjiData', kanjiList], (oldData) => {
              if (!oldData) return [newKanjiData];
              return oldData.map(item => 
                item.kanji === kanji ? newKanjiData : item
              );
            });

            return newKanjiData;
          } catch (error) {
            console.error(`AI lookup failed for ${kanji}:`, error);
            return {
              kanji,
              message: 'Not Found in Current Curriculum'
            };
          }
        }

        return {
          kanji,
          kanjiInfo: info || {},
          kunyomi: { readings: kunyomiReadings },
          onyomi: { readings: onyomiReadings }
        };
      }));

      // If we have vocabulary data, prepend it to the results
      if (vocabularyData) {
        return [vocabularyData, ...results];
      }

      return results;
    },
    enabled: !!kanjiList && kanjiList.length > 0,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 7 days
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      console.error('Query error:', error);
    }
  });
};

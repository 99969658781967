import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { MoonLoader } from 'react-spinners';

/**
 * Props:
 *   isOpen (bool): whether to show the popover
 *   boundingRect (object): the position/size info of the clicked element
 *   onClose (func): callback to close the popover
 *   content (string|ReactNode): the content to display
 */
function Popup({ isOpen, boundingRect, onClose, content }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport',
          padding: 8,
          altAxis: true,
          tether: false
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'left'],
          padding: 8
        }
      },
      {
        name: 'maxSize',
        enabled: true,
        options: {
          max: 'viewport',
          padding: 8
        },
      }
    ],
  });

  useEffect(() => {
    if (isOpen && update) {
      requestAnimationFrame(() => {
        update();
      });
    }
  }, [isOpen, update, content]);

  if (!isOpen || !boundingRect) return null;

  const renderContent = () => {
    if (content === 'Loading...') {
      return (
        <div className="flex items-center justify-center min-h-[100px] min-w-[300px]">
          <MoonLoader size={24} color="#009BCE" />
        </div>
      );
    }

    // Handle the new structured content format
    if (content?.result) {
      return (
        <div className="space-y-4">
          <div className="text-gray-900">
            {content.result.translation}
          </div>
          {content.result.nuance && (
            <div className="text-sm text-gray-600 italic">
              Context Notes: {content.result.nuance}
            </div>
          )}
          {content.result.grammar && (
            <div className="text-sm text-gray-600 italic">
              Grammar Notes: {content.result.grammar}
            </div>
          )}
        </div>
      );
    }

    return content;
  };

  return (
    // Full-screen overlay
    <div
      className="fixed inset-0 z-40 bg-black bg-opacity-10 transition-opacity duration-200"
      style={{ pointerEvents: 'auto' }}
      onClick={onClose}
    >
      {/* Add reference element */}
      <div
        ref={setReferenceElement}
        style={{
          position: 'absolute',
          top: boundingRect.top,
          left: boundingRect.left,
          width: boundingRect.width,
          height: boundingRect.height,
        }}
      />

      {/* Update popover container with Popper */}
      <div
        ref={setPopperElement}
        style={{ ...styles.popper, pointerEvents: 'auto' }}
        {...attributes.popper}
        className={`
          z-50 
          bg-white border border-gray-200 shadow-xl rounded-lg 
          p-6
          transform transition-transform duration-200
          origin-top
          ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}
        `}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Update arrow with Popper */}
        <div
          ref={setArrowElement}
          style={styles.arrow}
          className="
            w-0 h-0
            border-l-8 border-r-8 border-b-8
            border-l-transparent border-r-transparent border-b-white
            filter drop-shadow-sm
          "
          {...attributes.arrow}
        />

        {/* Close button (an 'X' icon) */}
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 
                     transition-colors duration-200 p-1 rounded-full 
                     hover:bg-gray-100"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Actual content area */}
        <div className="text-lg leading-relaxed text-gray-800 mt-1 
                      font-noto-sans-jp tracking-wide
                      prose prose-slate max-w-none
                      [&>p]:mb-3 [&>p:last-child]:mb-0">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default Popup;

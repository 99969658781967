import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createCallableFunction } from '../../../config/firebase';

const readerDefineSentence = createCallableFunction('readerDefineSentence');

export function useSentenceDefinition(sentence) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['sentenceDefinition', sentence],
    queryFn: async () => {
      const response = await readerDefineSentence({ sentence });
      return response.data;
    },
    enabled: !!sentence,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 7 days
  });
}
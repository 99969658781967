import React from 'react';
import { BiVolumeFull } from "react-icons/bi";
import { generateSSMLText } from '../../utils/generateSSML';

export function formatFuriganaText(text, handleTextToSpeech, isAudioPlaying, handleSentenceClick, handleKanjiClick) {
    if (!text) return text;

    // Split by HTML tags while preserving them
    const htmlParts = text.split(/(<[^>]*>)/);
    
    // Initialize variables to track sentences
    let currentSentence = [];
    let allElements = [];
    let sentenceCount = 0;

    const processSentence = (parts, isEndOfText = false) => {
        const elements = [];
        const fullSentence = currentSentence.join('');
        const sentenceId = `sentence-${sentenceCount}`;
        
        // Add TTS button at the start of the sentence
        elements.push(
            <button
                key={`tts-${sentenceId}`}
                className={`
                    mx-2 text-blue-500 hover:text-blue-700 
                    transition-colors duration-200
                    ${isAudioPlaying ? 'opacity-50 cursor-not-allowed' : ''}
                `}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isAudioPlaying) {
                        const ssmlText = generateSSMLText(fullSentence);
                        handleTextToSpeech(ssmlText);
                    }
                }}
                disabled={isAudioPlaying}
            >
                <BiVolumeFull className="w-6 h-6" />
            </button>
        );
        
        // Process the rest of the sentence
        for (let i = 0; i < parts.length; i++) {
            if (i % 2 === 0) {
                if (!parts[i + 1]) {
                    elements.push(
                        <span key={`text-${i}`}>
                            {parts[i]}
                        </span>
                    );
                } else {
                    const lastKanji = parts[i].match(/[\u4E00-\u9FAF]+$/)?.[0] || parts[i];
                    const prefix = parts[i].slice(0, -lastKanji.length);
                    
                    if (prefix) {
                        elements.push(
                            <span key={`prefix-${i}`}>
                                {prefix}
                            </span>
                        );
                    }
                    
                    elements.push(
                        <ruby key={`ruby-${i}`} className="hover:bg-purple-200 cursor-pointer">
                            <rb onClick={(e) => {
                                e.stopPropagation();
                                handleKanjiClick(lastKanji, e);
                            }}>{lastKanji}</rb>
                            <rt>{parts[i + 1]}</rt>
                        </ruby>
                    );
                }
            }
        }

        return (
            <span 
                key={sentenceId} 
                className="items-center mb-1 leading-relaxed cursor-pointer hover:bg-green-200"
                onClick={(e) => {
                    console.log('Clicked element:', e.currentTarget);
                    handleSentenceClick(fullSentence, e);
                }}
            >
                {elements}
            </span>
        );
    };

    return htmlParts.map((part, index) => {
        // If this is an HTML tag, handle it accordingly
        if (part.startsWith('<') && part.endsWith('>')) {
            return <span key={`html-${index}`} dangerouslySetInnerHTML={{ __html: part }} />;
        }
        
        // Process text parts for furigana
        if (part.includes('//')) {
            // First split by quotes to preserve quoted content
            const quoteParts = part.split(/(「[^」]*」)/);
            
            let sentences = [];
            quoteParts.forEach(quotePart => {
                // If this is a quoted part, keep it as one sentence
                if (quotePart.startsWith('「') && quotePart.endsWith('」')) {
                    sentences.push(quotePart);
                } else {
                    // For non-quoted parts, split by sentence endings
                    const splitSentences = quotePart.split(/([。！？])/);
                    sentences = sentences.concat(splitSentences);
                }
            });
            
            return sentences.map((sentence, idx) => {
                if (sentence === '') return null;
                
                // If this is a sentence ending punctuation
                if (sentence.match(/[。！？]/)) {
                    currentSentence.push(sentence);
                    const processedSentence = processSentence(currentSentence.join('').split('//'));
                    currentSentence = [];
                    sentenceCount++;
                    return processedSentence;
                }
                
                // Add to current sentence
                currentSentence.push(sentence);
                
                // If this is the last part and doesn't end with punctuation
                if (idx === sentences.length - 1) {
                    const processedSentence = processSentence(currentSentence.join('').split('//'), true);
                    currentSentence = [];
                    sentenceCount++;
                    return processedSentence;
                }
                
                return null;
            });
        }
        
        // Return regular text
        return (
            <span key={`text-${index}`}>
                {part}
            </span>
        );
    });
}